<template>
  <Modal class="news-visor default __fullHeight __search" ref="modal" :settings="modal" @close="newsClose">
    <div class="news-visor-title" slot="header">
      <p class="news-visor-icon">
        <iconic name="myspace" />
      </p>
    </div>
    <div class="news-visor-search">
      <SearchBox @input="inputSearch" :loading="loading" :value="q" />
    </div>
    <div class="news-visor-content">
      <div class="news-visor-list">
        <div class="news-visor-list-title">
          <div class="by_label">Busqueda</div>
          <div class="by_title_users">{{ totalNews + " Resultados" }}</div>
        </div>
        <table class="news-visor-table" width="100%">
          <NewsSearchItem v-for="(post, index) in news" :news="post" :key="index + post._id" />
        </table>
        <div class="news-visor-list-more">
          <LoadMore :hasmore="hasMore" label="Cargar más" :loading="loading" @more="getMorenews" />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
  import "./NewsVisor.scss";
  export default {
    components: {
      SearchBox: () => import("./SearchBox.vue"),
      NewsSearchItem: () => import("./NewsSearchItem.vue"),
    },
    data: function() {
      return {
        error: null,
        modal: { opened: true, outClose: true },
        loading: false,
        outSearch: null,
      };
    },
    methods: {
      newsClose: function() {
        this.$store.state.news.NewsSearch = null;
      },
      getNews: async function() {
        this.loading = true;
        try {
          const host = `news?page=${this.page}&q=${this.q}`;
          const { data } = await this.$api.get(`${host}`);
          this.newsLoaded(data);
        } catch (error) {
          this.$Swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      },
      newsLoaded: function(data) {
        if (Number(this.page) === 1) {
          this.$store.state.news.search.news = data.news;
          this.$store.state.news.search.total = data.total;
        } else {
          this.$store.state.news.search.news.push(...data.news);
        }
      },
      getMorenews: async function() {
        this.$store.state.news.search.page++;
        await this.getNews();
      },
      inputSearch: async function(value) {
        this.loading = true;
        this.$store.state.news.search.page = 1;
        this.$store.state.news.search.q = value;
        clearTimeout(this.outSearch);
        this.outSearch = setTimeout(() => {
          this.getNews();
        }, 500);
      },
    },
    mounted: function() {
      this.getMorenews();
    },
    computed: {
      hasMore: function() {
        const more = this.news.length < this.totalNews;
        return more;
      },
      q: function() {
        return this.$store.state.news.search.q;
      },
      page: function() {
        return this.$store.state.news.search.page;
      },
      news: function() {
        return this.$store.state.news.search.news;
      },
      totalNews: function() {
        return this.$store.state.news.search.total;
      },
    },
  };
</script>

<style lang="scss">
  .news-visor {
    &-list {
      position: relative;
    }
    &-newsTitle {
      padding: 0 $mpadding $mpadding $mpadding;
      font-family: $sec_font;
      font-size: 150%;
    }
    &-icon {
      font-size: 120px;
      margin-top: 28px;
    }
    &-list-title {
      padding: $mpadding;
      border-bottom: solid 1px $alto;
      display: flex;
      justify-content: space-between;
      .by_label {
        position: relative;
      }
    }
    &-table {
      .item-td {
        padding: $mpadding/2 $mpadding/2;
      }
      .item-tr .item-td:first-child {
        padding-left: $mpadding;
      }
      .item-tr .item-td:last-child {
        padding-right: $mpadding;
        &:not(.__textIgnore) {
          text-align: right;
        }
      }
      .item-tr:not(:last-child) {
        border-bottom: 1px solid $alto;
      }
      .item-tr {
        &:hover .item-td {
          background-color: #f5f5f5;
        }
        .__name {
          font-weight: bold;
        }
      }
    }
    .load-more {
      border-top: solid 1px $alto;
    }
  }
</style>
